.Post {
  background-color: #ffffff;
  border: 1px solid #dadadf;
  border-radius: 2px;
  margin-bottom: 16px;
  text-align: center;
}

.Post figure,
h5 {
  margin: 0 0;
}
